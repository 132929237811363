.experience {
  background: #1a1a1a;
  padding-top: 0.25em;
  padding-bottom: 1.5em;
  overflow: hidden;
}

.experience h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}

.experience h1 span {
  border-bottom: none;
  padding-bottom: 6px;
  position: relative;
}

.experience h1 span::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #11ABB0;
  margin-top: 6px;
  position: absolute;
  bottom: -6px;
  left: 0;
}

.experience-item {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #333333;
}

.experience-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.company-name {
  color: #11ABB0;
  font: 28px/34px 'opensans-bold', sans-serif;
  margin-bottom: 6px;
}

.company-info {
  color: #FFFFFF;
  font: 16px/24px 'librebaskerville-italic', serif;
  margin-bottom: 18px;
}

.company-info .bullet {
  margin: 0 6px;
}

.company-info .date {
  color: #B0C4DE;
}

.role-item {
  margin-bottom: 24px;
}

.role-item:last-child {
  margin-bottom: 0;
}

.role-item h4 {
  color: #FFFFFF;
  font: 20px/30px 'opensans-bold', sans-serif;
  margin-bottom: 12px;
  display: flex;
  align-items: baseline;
}

.role-item .role-date {
  font: 16px/24px 'opensans-regular', sans-serif;
  color: #B0C4DE;
  margin-left: 12px;
  font-style: italic;
}

.role-item p {
  color: #FFFFFF;
  font: 16px/24px 'opensans-regular', sans-serif;
  margin: 0;
}

.technologies {
  margin-top: 24px;
}

.technologies-title {
  font: 15px/24px 'opensans-bold', sans-serif;
  color: #fff;
  margin-right: 8px;
}

.tech-list {
  display: inline;
}

.tech-list a {
  color: #11ABB0;
  text-decoration: none;
  font-weight: 500;
}

.tech-list a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}

.gallery-link {
  margin-top: 16px;
}

.gallery-link a {
  color: #11ABB0;
  text-decoration: none;
  font: 14px/21px 'opensans-bold', sans-serif;
  font-weight: 500;
}

.gallery-link a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}