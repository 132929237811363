.skill {
  margin-bottom: 3em;
}

.skills {
  margin-bottom: 3em;
}

.skills-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skill-item {
  margin-bottom: 1em;
  display: flex;
}

.skill-description {
  color: #FFFFFF;
  font: 16px/24px 'opensans-light', sans-serif;
  margin: 0;
  padding-left: 1.5em;
  position: relative;
  display: block;
  width: 100%;
}

.skill-description::before {
  content: "•";
  color: #11ABB0;
  position: absolute;
  left: 0;
  font-size: 20px;
  line-height: 24px;
}

.languages-list {
  margin-top: 2em;
}

.language-bar-wrapper {
  margin-bottom: 1em;
}

.language-bar {
  background: #313131;
  padding: 0.2em 0.8em;
  border-radius: 3px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.language-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
  border-radius: 3px 0 0 3px;
}

.language-bar[data-level="Advanced"]::after {
  background-color: #11ABB0;
  width: 90%;
}

.language-bar[data-level="Intermediate"]::after {
  background-color: #F06000;
  width: 65%;
}

.language-bar[data-level="Beginner"]::after {
  background-color: #838C95;
  width: 30%;
}

.language-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.15em 0.4em;
  border-radius: 3px;
}

.language-name {
  color: #FFFFFF;
  font: 14px/18px 'opensans-bold', sans-serif;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
  margin-bottom: 0;
}

.language-name a,
.language-name a:visited {
  color: #FFFFFF;
  text-decoration: none;
}

.language-name a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}

.language-level {
  color: #FFFFFF;
  font: 12px/16px 'opensans-regular', sans-serif;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
}

.skills h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 30px;
}

.skills h1 span {
  border-bottom: none;
  padding-bottom: 6px;
  position: relative;
}

.skills h1 span::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #11ABB0;
  margin-top: 6px;
  position: absolute;
  bottom: -6px;
  left: 0;
}

#skills {
  background: #1a1a1a;
  padding-top: 0.25em;
  padding-bottom: 1.5em;
  overflow: hidden;
}

.skill .row {
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.skill .header-col {
  width: 100%;
  float: none;
  margin-bottom: 1em;
}

.skill .main-col {
  width: 100%;
  float: none;
}

.skill-section {
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: flex-start;
}

.skill-section .header-col {
  flex: 0 0 25%;
}

.skill-section .main-col {
  flex: 1;
}

.skills-subtitle {
  color: #FFFFFF;
  font: 16px/24px 'opensans-regular', sans-serif;
  margin-bottom: 30px;
}

.skill-category {
  margin-bottom: 25px;
}

.skill-category h3 {
  font: 16px/24px 'opensans-bold', sans-serif;
  color: #FFFFFF;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.skill-category h3::before {
  content: "•";
  color: #11ABB0;
  position: absolute;
  left: 0;
  font-size: 20px;
  line-height: 24px;
}

.skill-level {
  color: #95A3A3;
  font: 14px/20px 'opensans-regular', sans-serif;
  padding-left: 20px;
  margin-top: 5px;
}

/* Scoped header styles for Skills section */
#skills .header-col h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9);
}

#skills .header-col h1 span {
  display: inline-block;
  padding-bottom: 6px;
}

#skills .header-col h1::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background: #11ABB0;
  position: absolute;
  bottom: 0;
  left: 0;
}