#resume {
  background: #1a1a1a;
  padding-top: 0.25em;
  padding-bottom: 1.5em;
  overflow: hidden;
}

#resume a,
#resume a:visited {
  color: #11ABB0;
}

#resume a:hover,
#resume a:focus {
  color: #fff;
}

#resume h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

#resume h1 span {
  border-bottom: 3px solid #11ABB0;
  padding-bottom: 6px;
}

#resume h3 {
  font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col {
  /* Handled by global .header-col */
}

#resume .main-col {
  /* Handled by global .main-col */
}

/* Combined .education styles */
#resume .education {
  margin-top: 3em;
  margin-bottom: 3em;
  padding-bottom: 1.5em;
  border-bottom: 0.0625em solid #333333;
}

#resume .education h1 {
  /* Inherits #resume h1 */
  text-align: left; /* From layout.css */
  margin-bottom: 30px; /* From layout.css */
}

#resume .education h1 span {
  /* Inherits #resume h1 span */
}

#resume .education h3 {
  /* Inherits #resume h3 */
  color: #fff; /* From layout.css */
  margin-bottom: 10px; /* From layout.css */
}

#resume .education .info {
  /* Inherits global .info */
}

#resume .education .info span {
  /* Inherits global .info span */
}

#resume .education .date {
 /* Inherits global .date */
}

/* Added from layout.css */
#resume .education .description {
	color: #E0E0E0;
	margin: 12px 0;
	font: 15px/24px 'opensans-regular', sans-serif;
}

.education,
.work {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #333333;
}

#resume .info {
  font: 16px/24px 'librebaskerville-italic', serif;
  color: #FFFFFF;
  margin-bottom: 18px;
  margin-top: 9px;
  font-weight: 400;
}

#resume .info span {
  margin-right: 5px;
  margin-left: 5px;
}

#resume .date {
  color: #B0C4DE;
  font-style: italic;
}